<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="付款方式種類" prop="config.type">
        <BaseElSelect v-model="formData.config.type" placeholder="請選擇">
          <BaseElSelectOption v-for="item in pubAptPaymentTypes" :key="item.value" :label="item.name" :value="item.value" />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="付款方式名稱" prop="displayName">
        <BaseElInput v-model="formData.displayName" placeholder="請輸入付款方式名稱" />
      </BaseElFormItem>
      <!-- </BaseElForm>
    <BaseElForm ref="formRef" :disabled="disableForm" :rules="formRules" :model="formData" label-position="top"> -->
      <!-- <LinePayFrom v-if="formData.paymentType === 'linepay'" :formData.sync="formData.linepay" />
      <JkosPayForm v-if="formData.paymentType === 'jkos'" :formData.sync="formData.jkos" />
      <NewebPayForm v-if="formData.paymentType === 'newebpay'" :formData.sync="formData.newebpay" /> -->
      <TapPayForm v-if="get(formData, 'config.type') === 'tapPay'" :formData.sync="formData.config.tapPay" />
    </BaseElForm>

    <span slot="footer" class="dialog-footer">
      <div class>
        <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
        <BaseElButton type="primary" @click="handleConfirm">
          確認
        </BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
// import LinePayFrom from '@/components/Payment/LinePayForm.vue'
// import JkosPayForm from '@/components/Payment/JkosPayForm.vue'
// import NewebPayForm from '@/components/Payment/NewebPayForm.vue'
import TapPayForm from '@/components/Payment/TapPayForm.vue'
import { pubAptPaymentTypes } from '@/config/pubApt'
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import { get } from 'lodash'
import { noEmptyRules } from '@/validation'
import { CreatePubAptPaymentConfig, UpdatePubAptPaymentConfig } from '@/api/pubApt'
import { useShop } from '@/use/shop'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'EditPubAptPaymentDialog',
  components: {
    // LinePayFrom,
    // JkosPayForm,
    // NewebPayForm,
    TapPayForm,
  },
  props: {
    selectPayment: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'create',
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const formData = reactive({
      config: {
        type: '',
        tapPay: {
          appID: '',
          appKey: '',
          partnerKey: '',
          partnerAccount: '',
          merchantId: '',
        },
      },
      displayName: '',
    })
    const formRef = ref(null)
    const formRules = reactive({
      config: {
        type: [noEmptyRules()],
        tapPay: {
          appID: [noEmptyRules()],
          appKey: [noEmptyRules()],
          partnerKey: [noEmptyRules()],
          partnerAccount: [noEmptyRules()],
          merchantId: [noEmptyRules()],
        },
      },
      displayName: [noEmptyRules()],
    })
    const disableForm = computed(() => (props.type === 'edit'))
    const dialogTitle = computed(() => {
      let title
      if (props.type === 'create') title = '新增付款方式'
      if (props.type === 'edit') title = '編輯付款方式'
      return title
    })
    const handleConfirm = async () => {
      if (props.type === 'create') await createPubAptPayment()
      if (props.type === 'edit') await updateDepositPayment()
    }
    const createPubAptPayment = async () => {
      const valid = formUtils.checkForm(formRef.value)
      if (valid) {
        const [, err] = await CreatePubAptPaymentConfig({
          shopId: shopId.value,
          data: formData,
        })
        if (err) return window.$message.error(err)

        window.$message.success('創建成功！')
        emit('refresh')
        emit('close')
      }
    }
    const compactData = computed(() => ({
      config: formData.config,
      displayName: formData.displayName,
    }))

    const updateDepositPayment = async () => {
      const valid = await formUtils.checkForm(formRef.value)

      if (valid) {
        const [, err] = await UpdatePubAptPaymentConfig({
          shopId: shopId.value,
          id: formData.id,
          data: compactData.value,
        })
        if (err) return window.$message.error(err)

        window.$message.success('更新成功！')
        emit('refresh')
        emit('close')
      }
    }
    onMounted(async () => {
      // console.log(props.selectPayment)
      if (props.selectPayment) {
        Object.assign(formData, props.selectPayment)
        console.log('formData', formData)
      }
    })
    return {
      get,
      handleConfirm,
      pubAptPaymentTypes,
      formData,
      formRules,
      disableForm,
      dialogTitle,
      formRef,
    }
  },
})
</script>

<style scoped lang="scss"></style>
