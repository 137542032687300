<template>
  <div class="sales-parameter">
    <section class="section-block">
      <SectionTitle title="跨境預約啟用設定" hideBtn />
      <div>
        <BaseElForm v-model="formData" label-position="left" label-width="250px">
          <BaseElFormItem label="開放跨境訪客預約">
            <BaseElSwitch
              v-model="formData.enable"
              data-cy="allow-order-multi-people-switch"
              class="switch-input"
              active-text="開啟"
              inactive-text="關閉"
              @change="updateEnableSetting"
            />
          </BaseElFormItem>
          <p class="text-sub text-gray-60">當您啟用跨境預約時，客戶端的預約流程會跳過指定服務人員的階段，服務人員分將依照前後14天內預約數，較低者優先，相同則隨機。</p>
        </BaseElForm>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, watch } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle'
import { get } from 'lodash'
export default defineComponent({
  name: 'EnableSettings',
  components: { SectionTitle },
  props: {
    content: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const formData = reactive({
      enable: false,
    })
    const updateEnableSetting = async () => {
      emit('updatePubApt', formData)
    }
    const syncData = async () => {
      formData.enable = JSON.parse(JSON.stringify(get(props, 'content.enable')))
    }
    watch(props, async () => {
      await syncData()
    })
    return {
      formData,
      updateEnableSetting,
    }
  },
})
</script>

<style scoped lang="scss">
</style>
