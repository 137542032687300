<template>
  <el-dialog
    title="查看付款方式"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem v-for="field in displayData" :key="field.label" :label="field.label">
        <div class="text-[#5E5E5E]">
          <span>{{ field.value }}</span>
        </div>
      </BaseElFormItem>
    </BaseElForm>
  </el-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'ViewPubAptPaymentDialog',
  props: {
    selectPayment: {
      type: Object,
      default: () => ({}),
    },
    pubAptPaymentTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    const displayData = computed(() => {
      const config = props.selectPayment
      console.log(config)
      return [
        { label: '串接狀態', value: config.verified ? '串接成功' : '串接失敗' },
        // { label: '啟用狀態', value: config.enable ? '開啟' : '關閉' },
        { label: '付款方式種類', value: get(config, 'config.type') ? get(props.pubAptPaymentTypes, get(config, 'config.type')).name : '-' },
        { label: '付款方式名稱', value: get(config, 'displayName') || '-' },
        { label: 'TapPay Partner Account', value: get(config, 'config.tapPay.partnerAccount') || '-' },
        { label: 'TapPay Partner Key', value: get(config, 'config.tapPay.partnerKey') || '-' },
        { label: 'Merchant ID', value: get(config, 'config.tapPay.merchantId') || '-' },
        { label: 'appID', value: get(config, 'config.tapPay.appID') || '-' },
        { label: 'appKey', value: get(config, 'config.tapPay.appKey') || '-' },
      ]
    })
    return {
      displayData,
    }
  },
})
</script>

<style scoped lang="scss"></style>
